import React from 'react';
import { storeActivePage, initializeCommon } from 'models/common';
import { initializeHome } from 'models/home';
import DonateRoute from './Donate';
import EducationProgramRoute from './EducationProgram';
import AdvocacyProgramRoute from './AdvocacyProgram';
import AppAccountDeletion from './AppAccountDeletion';
import AppPrivacyPolicyAndTerms from './AppPrivacyPolicyAndTerms';
import EventRoute from './Event';
import CampaignRoute from './Campaign';
import VideoRoute from './Video';
import BlogTypeRoute from './BlogType';
import BlogRoute from './Blog';
import VisionRoute from './Vision';
import FeatureCardsRoute from './FeatureCards';
import HalfTextRoute from './HalfText';
import BlockCardsRoute from './BlockCards';
import BannerRoute from './Banner';
import BlankRoute from './Blank';
import PageRoute from './Page';
import PicAndVideoModulesRoute from './PicAndVideoModules';
import PicModulesRoute from './PicModules';
import VideoModulesRoute from './VideoModules';
import AvatarsRoute from './Avatars';
import LogoApplyRoute from './LogoApply';
import TextModulesRoute from './TextModules';
import MissionRoute from './Mission';
import CultureRoute from './Culture';
import MethodologyRoute from './Methodology';
import BrandBookRoute from './BrandBook';
import ProjectRoute from './Project';
import ImpactRoute from './Impact';
import ImpactReportRoute from './ImpactReport';
import TeamRoute from './Team';
import MentorRoute from './Mentor';
import CareerRoute from './Career';
import RedirectRoute from './Redirect';
import DonationEvent from './DonationEvent';

// 筆記：執行順序 onEnter -> render -> <App />
const routes = {
	path: '',
	components: () => [],
	render: (_, children) => children,
	onEnter: () => {
		console.log('on Enter');
		window.scrollTo(0, 0);
		window.history.scrollRestoration = 'manual';
		console.log('on Enter / end');
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'home' */ './Home')],
			render: ([Home]) => <Home />,
			onEnter: async ({ store }) => {
				console.log('on Enter home');
				await store.dispatch(initializeCommon());
				await store.dispatch(storeActivePage('home'));
				await store.dispatch(initializeHome());
				console.log('on Enter home / end');
			},
		},
		{
			path: '/en',
			components: () => [import(/* webpackChunkName: 'home' */ './HomeEN')],
			render: ([Home]) => <Home />,
			onEnter: async ({ store }) => {
				console.log('on Enter home');
				await store.dispatch(initializeCommon());
				await store.dispatch(storeActivePage('home'));
				await store.dispatch(initializeHome());
				console.log('on Enter home / end');
			},
		},
		{
			path: '/tw',
			components: () => [],
			render: (_, children) => children,
			onEnter: async ({ store }) => {
				// TODO: 目前在切換路由時，universal router 皆會重新進入此區塊重打 API，造成效能、網站頁面切換延遲，若要改善考慮將 dispatch 區塊抽離至入口檔 index.js ? 但須注意是否有特定功能是依賴此特性進行實作而壞掉。
				await store.dispatch(initializeCommon());
			},
			children: [
				{
					path: '',
					components: () => [import(/* webpackChunkName: 'home' */ './Home')],
					render: ([Home]) => <Home />,
					onEnter: async ({ store }) => {
						console.log('on Enter home');
						await store.dispatch(storeActivePage('home'));
						await store.dispatch(initializeHome());
						console.log('on Enter home / end');
					},
				},
				DonateRoute,
				EducationProgramRoute,
				AdvocacyProgramRoute,
				EventRoute,
				CampaignRoute,
				VideoRoute,

				// 動態路由
				PageRoute,
				PicAndVideoModulesRoute,
				FeatureCardsRoute,
				BlockCardsRoute,
				PicModulesRoute,
				AvatarsRoute,
				BannerRoute,
				BlankRoute,
				LogoApplyRoute,
				HalfTextRoute,
				VideoModulesRoute,
				AppAccountDeletion,
				AppPrivacyPolicyAndTerms,
				// 動態路由
				BlogTypeRoute,
				// 動態路由
				BlogRoute,
				// 動態路由
				DonationEvent,
				VisionRoute,
				TextModulesRoute,
				MissionRoute,
				CultureRoute,
				MethodologyRoute,
				BrandBookRoute,
				// 動態路由
				ProjectRoute,
				// 動態路由
				ImpactRoute,
				ImpactReportRoute,
				TeamRoute,
				MentorRoute,
				CareerRoute,
			],
		},
		RedirectRoute,
		// children route no need i18n
	],
};

export default routes;
