import { createAction, handleActions } from 'redux-actions';
import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';
import {
	isGuiNumberValid,
	isNationalIdentificationNumberValid,
	isResidentCertificateNumberValid,
} from 'taiwan-id-validator';
import { testRegex, getLangfromURL } from 'util/helper';
import history from 'store/history';
import { setPageLoading } from 'models/common';

const plainTextOnly = str => testRegex(str, /[\u4E00-\u9FFFA-Za-z]/g); // Chinese chars & A-Z & a-z
const haveFullwidth = str => testRegex(str, /[\uFF10-\uFF19\uFF21-\uFF3A\uFF41-\uFF5A]/g); // fullwidth numbers & A-Z & a-z

export const checkDonate = createAction('CHECK_DONATE', orderId => async () => {
	const { status, message, data } = await wrapFetch(
		'tapPay',
		{
			method: 'GET',
		},
		{ order_number: orderId || '' },
	);

	if (status !== 200) {
		console.error(message);
	}

	const donateValid = data !== null;

	if (!donateValid) {
		history.push(`/${getLangfromURL()}/donate/support-migrants?error=true`);
	}

	const donateType = data.type === 'RECURRING' ? 'monthly' : 'single';

	return {
		donateValid,
		orderId: data.order_number,
		donateName: data.name,
		donateType,
		amount: data.amount,
	};
});

export const setDonateReceipt = createAction('SET_DONATE_RECEIPT', receipt => {
	const { type } = receipt;
	const valid = type && type.length > 0;
	return { valid, receipt: type };
});

export const setDonateReceiptName = createAction('SET_DONATE_RECEIPT_NAME', receiptName => {
	const valid = receiptName.length > 0 && plainTextOnly(receiptName);
	return { valid, receiptName };
});

export const setDonateId = createAction('SET_DONATE_ID', id => {
	const valid =
		isResidentCertificateNumberValid(id) ||
		isGuiNumberValid(id) ||
		isNationalIdentificationNumberValid(id);
	return { valid, id };
});

export const setDonateBirthday = createAction('SET_DONATE_BIRTHDAY', birthday => {
	const fomatDate = birthday.toLocaleDateString('en-ZA');
	const valid = fomatDate !== 'Invalid Date' && !haveFullwidth(birthday);
	return { valid, birthday: fomatDate };
});

export const setDonateMessage = createAction('SET_DONATE_MESSAGE', message => {
	const valid = !haveFullwidth(message);
	return { valid, message };
});

export const fetchReceipt = createAction('FETCH_RECEIPT', () => async (_, getState) => {
	const {
		donateReceipt: {
			orderId,
			donateReceipt,
			donateReceiptName,
			donateId,
			donateBirthday,
			donateMessage,
		},
	} = getState();

	const body = {
		order_number: orderId,
		receipt_type: donateReceipt.receipt,
		receipt_name: donateReceiptName.receiptName,
		receipt_id_number: donateId.id,
		receipt_date_of_birth: donateBirthday.birthday,
		message: donateMessage.message,
	};

	console.log('form data:', body);

	const { status, message, data } = await wrapFetch('tapPay/receipt', {
		method: 'POST',
		body: JSON.stringify(body),
	});

	if (status !== 200) {
		console.log(message);
	}

	history.push(`/${getLangfromURL()}/donate/support-migrants/welcome`);

	return data;
});

export const initializeDonateReceipt = createAction(
	'INITIALIZE_DONATE_RECEIPT',
	orderId => async dispatch => {
		await dispatch(checkDonate(orderId));
		await dispatch(setPageLoading());
	},
);

const reducer = {
	donateReceipt: handleActions(
		{
			CHECK_DONATE_FULFILLED: (state, action) => ({
				...state,
				donateValid: action.payload.donateValid,
				orderId: action.payload.orderId,
				donateName: action.payload.donateName,
				donateType: action.payload.donateType,
				orderPayment: action.payload.orderPayment,
				amount: action.payload.amount,
			}),
			SET_DONATE_RECEIPT: (state, action) => ({
				...state,
				donateReceipt: {
					valid: action.payload.valid,
					receipt: action.payload.receipt,
				},
			}),
			SET_DONATE_RECEIPT_NAME: (state, action) => ({
				...state,
				donateReceiptName: {
					valid: action.payload.valid,
					receiptName: action.payload.receiptName,
				},
			}),
			SET_DONATE_ID: (state, action) => ({
				...state,
				donateId: {
					valid: action.payload.valid,
					id: action.payload.id,
				},
			}),
			SET_DONATE_BIRTHDAY: (state, action) => ({
				...state,
				donateBirthday: {
					valid: action.payload.valid,
					birthday: action.payload.birthday,
				},
			}),
			SET_DONATE_MESSAGE: (state, action) => ({
				...state,
				donateMessage: {
					valid: action.payload.valid,
					message: action.payload.message,
				},
			}),
			FETCH_RECEIPT_FULFILLED: (state, action) => ({
				...state,
				receiptResult: action.payload,
			}),
		},
		{
			donateValid: false,
			orderId: '',
			donateName: '',
			donateType: '',
			orderPayment: '',
			amount: '',
			donateReceipt: {
				valid: false,
				receipt: '',
			},
			donateReceiptName: {
				valid: false,
				receiptName: '',
			},
			donateId: {
				valid: false,
				id: '',
			},
			donateBirthday: {
				valid: false,
				birthday: '',
			},
			donateMessage: {
				valid: false,
				message: '',
			},
			receiptResult: null,
		},
	),
};

const selectDonateReceipt = state => ({
	...state.donateReceipt,
});

export const useDonateReceipt = () =>
	useRedux(selectDonateReceipt, {
		setDonateReceipt,
		setDonateReceiptName,
		setDonateId,
		setDonateBirthday,
		setDonateMessage,
		fetchReceipt,
	});

export default { reducer };
