import { createAction, handleActions } from 'redux-actions';
import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';
import { testRegex, getParameterByName, getLangfromURL } from 'util/helper';
import { data as taiwanCity } from 'util/taiwan-post-code.json';
import history from 'store/history';
import { setHubspotTrackingEmail } from 'models/common';

const plainTextOnly = str => testRegex(str, /[\u4E00-\u9FFFA-Za-z]/g); // Chinese chars & A-Z & a-z
const haveFullwidth = str => testRegex(str, /[\uFF10-\uFF19\uFF21-\uFF3A\uFF41-\uFF5A]/g); // fullwidth numbers & A-Z & a-z

export const setTPSDK = createAction('SET_TP_SDK', () => {
	const { TAPPAY_CLIENT_APPID, TAPPAY_CLIENT_APPKEY, TAPPAY_CLIENT_SERVER } = process.env;

	if (window.TPDirect) {
		window.TPDirect.setupSDK(TAPPAY_CLIENT_APPID, TAPPAY_CLIENT_APPKEY, TAPPAY_CLIENT_SERVER);
	}
});

export const setShowError = createAction('SET_SHOW_ERROR', boo => boo);

export const setDonateStep = createAction('SET_DONATE_STEP', step => step);

export const setDonateAmount = createAction('SET_DONATE_AMOUNT', amount => {
	const valid = amount % 1 === 0 && amount >= 100 && !haveFullwidth(amount);
	return { valid, amount };
});

export const setDonateType = createAction('SET_DONATE_TYPE', type => async (dispatch, getState) => {
	const {
		donationInfo: { banner },
		common: { activePage },
	} = getState();

	if (activePage === 'donate') {
		dispatch(setDonateAmount(type === 'monthly' ? 500 : 1000));
	} else {
		dispatch(
			setDonateAmount(
				type === 'monthly' ? banner.recurring_options[1].amount : banner.one_time_options[1].amount,
			),
		);
	}
	return type;
});

export const setDonateLastname = createAction('SET_DONATE_LASTNAME', lastname => {
	const valid = lastname.length > 0 && plainTextOnly(lastname);
	return { valid, lastname };
});

export const setDonateFirstname = createAction('SET_DONATE_FIRSTNAME', firstname => {
	const valid = firstname.length > 0 && plainTextOnly(firstname);
	return { valid, firstname };
});

export const setDonateGender = createAction('SET_DONATE_GENDER', gender => {
	const { name } = gender;
	const valid = name && !haveFullwidth(name);
	return { valid, gender: name };
});

export const setDonateNumber = createAction('SET_DONATE_NUMBER', number => {
	const valid = testRegex(number, /^09\d{8}$/) && !haveFullwidth(number);
	return { valid, number };
});

export const setDonateEmail = createAction('SET_DONATE_EMAIL', email => {
	const valid =
		testRegex(
			email,
			/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i,
		) && !haveFullwidth(email);
	return { valid, email };
});

export const setDonateCode = createAction('SET_DONATE_CODE', code => {
	const valid = testRegex(code, /^\d{3}$/) && !haveFullwidth(code);
	return { valid, code };
});

export const setDonateDist = createAction('SET_DONATE_DIST', dist => async (dispatch, getState) => {
	const {
		donateForm: { donateCity },
	} = getState();

	const city = taiwanCity.filter(i => i.city === donateCity.city)[0];
	const code =
		city && city.dists.filter(i => i.dist === dist)[0]
			? city.dists.filter(i => i.dist === dist)[0].code
			: '';

	if (code) {
		dispatch(setDonateCode(code));
	}

	const valid = dist.length > 0 && !haveFullwidth(dist);
	return { valid, dist };
});

export const setDonateCity = createAction('SET_DONATE_CITY', city => async (dispatch, getState) => {
	const {
		donateForm: { donateCity },
	} = getState();

	if (city !== donateCity) {
		dispatch(setDonateDist(''));
		dispatch(setDonateCode(''));
	}

	const valid = city.length > 0 && !haveFullwidth(city);
	return { valid, city };
});

export const setDonateAddress = createAction('SET_DONATE_ADDRESS', address => {
	const valid = address.length > 0 && !haveFullwidth(address);
	return { valid, address };
});

export const setDonatePayment = createAction('SET_DONATE_PAYMENT', payment => {
	const valid = payment.length > 0;
	return { valid, payment };
});

export const setDonateCardValidity = createAction('SET_DONATE_CARD_VALIDITY', valid => valid);

export const setDonateCardType = createAction('SET_DONATE_CARD_TYPE', type => type);

export const setDonateExpiryValidity = createAction('SET_DONATE_EXPIRY_VALIDITY', valid => valid);

export const setDonateCCVValidity = createAction('SET_DONATE_CCV_VALIDITY', valid => valid);

const getCardPrime = createAction('GET_CARD_PRIME', () => async () => {
	if (!window.TPDirect) {
		console.log('window has no TPDirect function');
		return '';
	}

	function isIos() {
		return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
	}

	function forceBlurIos() {
		if (!isIos()) {
			return;
		}
		const input = document.createElement('input');
		input.setAttribute('type', 'text');
		// Insert to active element to ensure scroll lands somewhere relevant
		document.activeElement.prepend(input);
		input.focus();
		input.parentNode.removeChild(input);
	}

	// fix keyboard issue in iOS device
	forceBlurIos();

	// 取得 TapPay Fields 的 status
	const tappayStatus = window.TPDirect.card.getTappayFieldsStatus();

	// 確認是否可以 getPrime
	if (tappayStatus.canGetPrime === false) {
		console.log('can not get prime');
		return '';
	}

	const getPrime = async () => {
		const prime = await new Promise((resolve, reject) => {
			window.TPDirect.card.getPrime(result => {
				if (result.status !== 0) {
					console.log(`get prime error ${result.msg}`);
					reject(result.msg);
				}

				resolve(result.card.prime);
			});
		});

		return prime;
	};

	const prime = await getPrime();

	return prime;
});

const getLinePrime = createAction('GET_LINE_PRIME', () => async () => {
	if (!window.TPDirect) {
		console.log('window has no TPDirect function');
		return '';
	}

	const getPrime = async () => {
		const prime = await new Promise((resolve, reject) => {
			window.TPDirect.linePay.getPrime(result => {
				if (result.status !== 0) {
					console.log(`get prime error ${result.msg}`);
					reject(result.msg);
				}

				resolve(result.prime);
			});
		});

		return prime;
	};

	const prime = await getPrime();

	return prime;
});

const getJKOPrime = createAction('GET_JKO_PRIME', () => async () => {
	if (!window.TPDirect) {
		console.log('window has no TPDirect function');
		return '';
	}

	const getPrime = async () => {
		const prime = await new Promise((resolve, reject) => {
			window.TPDirect.jkoPay.getPrime(result => {
				if (result.status !== 0) {
					console.log(`get prime error ${result.msg}`);
					reject(result.msg);
				}

				resolve(result.prime);
			});
		});

		return prime;
	};

	const prime = await getPrime();

	return prime;
});

export const checkSupportApplePay = createAction(
	'CHECK_SUPPORT_APPLE_PAY',
	() => async (_, getState) => {
		const {
			donateForm: { donateAmount },
		} = getState();

		const data = {
			supportedNetworks: ['MASTERCARD', 'VISA', 'JCB'],
			supportedMethods: ['apple_pay'],
			displayItems: [
				{
					label: '捐款支持One Forty',
					amount: {
						currency: 'TWD',
						value: donateAmount.amount,
					},
				},
			],
			total: {
				label: 'One Forty',
				amount: {
					currency: 'TWD',
					value: donateAmount.amount,
				},
			},
			// optional
			options: {
				requestPayerEmail: false,
				requestPayerName: false,
				requestPayerPhone: false,
				requestShipping: false,
			},
		};

		if (!window.TPDirect) {
			console.log('window has no TPDirect function');
			return false;
		}

		const availability = window.TPDirect.paymentRequestApi.checkAvailability();
		// Check if PaymentRequestAPI is available.
		if (!availability) {
			console.log('PaymentRequestAPI is not available');
			return false;
		}

		const { TAPPAY_CLIENT_APPLEPAY_MERCHANT_IDENTIFIER } = process.env;
		window.TPDirect.paymentRequestApi.setupApplePay({
			// required, your apple merchant id
			merchantIdentifier: TAPPAY_CLIENT_APPLEPAY_MERCHANT_IDENTIFIER,
			// defaults to 'TW'
			countryCode: 'TW',
		});

		const getPaymentRequest = async () => {
			const paymentRequest = await new Promise((resolve, reject) => {
				window.TPDirect.paymentRequestApi.setupPaymentRequest(data, result => {
					switch (false) {
						case !!result.browserSupportPaymentRequest:
							console.log(
								'result.browserSupportPaymentRequest:',
								result.browserSupportPaymentRequest,
							);
							reject(result.browserSupportPaymentRequest);
							break;

						case !!result.canMakePaymentWithActiveCard:
							console.log(
								'result.canMakePaymentWithActiveCard:',
								result.canMakePaymentWithActiveCard,
							);
							reject(result.canMakePaymentWithActiveCard);
							break;

						default:
							console.log(result);
					}

					resolve(true);
				});
			});

			return paymentRequest;
		};

		const paymentRequest = await getPaymentRequest();

		return paymentRequest;
	},
);

const getApplePrime = createAction('GET_APPLE_PRIME', () => async () => {
	if (!window.TPDirect) {
		console.log('window has no TPDirect function');
		return '';
	}

	const getPrime = async () => {
		const prime = await new Promise((resolve, reject) => {
			window.TPDirect.paymentRequestApi.getPrime(result => {
				if (result.status !== 0) {
					console.log(`get prime error ${result.msg}`);
					reject(result.msg);
				}
				console.log('result.prime:', result.prime);
				resolve(result.prime);
			});
		});

		return prime;
	};

	const prime = await getPrime();

	return prime;
});
export const setFetchingTappay = createAction('SET_FETCHING_TAPPAY', boo => boo);

export const fetchTappay = createAction('FETCH_TAPPAY', () => async (dispatch, getState) => {
	dispatch(setFetchingTappay(true));

	const {
		donateForm: { donatePayment },
		common: { activePage },
	} = getState();

	switch (donatePayment.payment) {
		case 'line':
			await dispatch(getLinePrime());
			break;
		case 'jko':
			await dispatch(getJKOPrime());
			break;
		case 'apple':
			await dispatch(getApplePrime());
			break;
		case 'card':
			await dispatch(getCardPrime());
			break;
		default:
			return null;
	}

	const {
		donateForm: {
			donateType,
			donateAmount,
			donateLastname,
			donateFirstname,
			donateGender,
			donateNumber,
			donateEmail,
			donateCode,
			donateDist,
			donateCity,
			donateAddress,
			TPPrime,
		},
	} = getState();

	if (!TPPrime) {
		dispatch(setFetchingTappay(false));
		return null;
	}

	const method = () => {
		switch (donatePayment.payment) {
			case 'line':
				return 'line_pay';
			case 'jko':
				return 'jko_pay';
			case 'apple':
				return 'apple_pay';
			case 'card':
				return 'direct_pay';
			default:
				return '';
		}
	};

	let body;

	if (activePage === 'donate') {
		body = {
			method: method(),
			type: donateType === 'monthly' ? 'recurring' : 'one_time',
			amount: donateAmount.amount,
			prime: TPPrime,
			cardholder: {
				lastname: donateLastname.lastname,
				firstname: donateFirstname.firstname,
				gender: donateGender.gender,
				number: donateNumber.number,
				email: donateEmail.email,
				code: donateCode.code,
				dist: donateDist.dist,
				city: donateCity.city,
				address: donateAddress.address,
			},
			utm: {
				fields: [
					{
						name: 'utm_source',
						value: getParameterByName('utm_source') ?? '',
					},
					{
						name: 'utm_medium',
						value: getParameterByName('utm_medium') ?? '',
					},
					{
						name: 'utm_campaign',
						value: getParameterByName('utm_campaign') ?? '',
					},
					{
						name: 'utm_term',
						value: getParameterByName('utm_term') ?? '',
					},
					{
						name: 'utm_content',
						value: getParameterByName('utm_content') ?? '',
					},
				],
				context: {
					hutk: document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, '$1'),
					pageUri: window.location.href,
					pageName: 'donate',
				},
			},
		};
	} else {
		body = {
			method: method(),
			type: donateType === 'monthly' ? 'recurring' : 'one_time',
			amount: donateAmount.amount,
			event_donation_page_url: activePage,
			prime: TPPrime,
			cardholder: {
				lastname: donateLastname.lastname,
				firstname: donateFirstname.firstname,
				gender: donateGender.gender,
				number: donateNumber.number,
				email: donateEmail.email,
				code: donateCode.code,
				dist: donateDist.dist,
				city: donateCity.city,
				address: donateAddress.address,
			},
			utm: {
				fields: [
					{
						name: 'utm_source',
						value: getParameterByName('utm_source') ?? '',
					},
					{
						name: 'utm_medium',
						value: getParameterByName('utm_medium') ?? '',
					},
					{
						name: 'utm_campaign',
						value: getParameterByName('utm_campaign') ?? '',
					},
					{
						name: 'utm_term',
						value: getParameterByName('utm_term') ?? '',
					},
					{
						name: 'utm_content',
						value: getParameterByName('utm_content') ?? '',
					},
				],
				context: {
					hutk: document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, '$1'),
					pageUri: window.location.href,
					pageName: 'donate',
				},
			},
		};
	}

	console.log('form data:', body);

	const { status, message, data } = await wrapFetch('tapPay', {
		method: 'POST',

		body: JSON.stringify(body),
	});

	switch (true) {
		case status !== 200:
			dispatch(setShowError(true));
			throw new Error(message);
		case data.status !== 0:
			dispatch(setShowError(true));
			break;
		case donatePayment.payment === 'apple':
			dispatch(setHubspotTrackingEmail(donateEmail.email));
			history.push(
				`/${getLangfromURL()}/donate/support-migrants/success?order_number=${data.order_number}`,
			);
			break;
		default:
			dispatch(setHubspotTrackingEmail(donateEmail.email));
			window.location.replace(data.payment_url);
			break;
	}

	return data;
});

export const initializeDonateForm = createAction('INITIALIZE_DONATE_FORM', () => async dispatch => {
	dispatch(setTPSDK());
	dispatch(setShowError(getParameterByName('error') === 'true'));
});

const reducer = {
	donateForm: handleActions(
		{
			SET_SHOW_ERROR: (state, action) => ({
				...state,
				showError: action.payload,
			}),
			SET_DONATE_STEP: (state, action) => ({
				...state,
				donateStep: action.payload,
			}),
			SET_DONATE_AMOUNT: (state, action) => ({
				...state,
				donateAmount: {
					valid: action.payload.valid,
					amount: action.payload.amount,
				},
			}),
			SET_DONATE_TYPE_FULFILLED: (state, action) => ({
				...state,
				donateType: action.payload,
			}),
			SET_DONATE_LASTNAME: (state, action) => ({
				...state,
				donateLastname: {
					valid: action.payload.valid,
					lastname: action.payload.lastname,
				},
			}),
			SET_DONATE_FIRSTNAME: (state, action) => ({
				...state,
				donateFirstname: {
					valid: action.payload.valid,
					firstname: action.payload.firstname,
				},
			}),
			SET_DONATE_GENDER: (state, action) => ({
				...state,
				donateGender: {
					valid: action.payload.valid,
					gender: action.payload.gender,
				},
			}),
			SET_DONATE_NUMBER: (state, action) => ({
				...state,
				donateNumber: {
					valid: action.payload.valid,
					number: action.payload.number,
				},
			}),
			SET_DONATE_EMAIL: (state, action) => ({
				...state,
				donateEmail: {
					valid: action.payload.valid,
					email: action.payload.email,
				},
			}),
			SET_DONATE_CODE: (state, action) => ({
				...state,
				donateCode: {
					valid: action.payload.valid,
					code: action.payload.code,
				},
			}),
			SET_DONATE_DIST_FULFILLED: (state, action) => ({
				...state,
				donateDist: {
					valid: action.payload.valid,
					dist: action.payload.dist,
				},
			}),
			SET_DONATE_CITY_FULFILLED: (state, action) => ({
				...state,
				donateCity: {
					valid: action.payload.valid,
					city: action.payload.city,
				},
			}),
			SET_DONATE_ADDRESS: (state, action) => ({
				...state,
				donateAddress: {
					valid: action.payload.valid,
					address: action.payload.address,
				},
			}),
			SET_DONATE_PAYMENT: (state, action) => ({
				...state,
				donatePayment: {
					valid: action.payload.valid,
					payment: action.payload.payment,
				},
			}),
			SET_DONATE_CARD_VALIDITY: (state, action) => ({
				...state,
				donateCardValidity: action.payload,
			}),
			SET_DONATE_CARD_TYPE: (state, action) => ({
				...state,
				donateCardType: action.payload,
			}),
			SET_DONATE_EXPIRY_VALIDITY: (state, action) => ({
				...state,
				donateExpiryValidity: action.payload,
			}),
			SET_DONATE_CCV_VALIDITY: (state, action) => ({
				...state,
				donateCCVValidity: action.payload,
			}),
			GET_CARD_PRIME_FULFILLED: (state, action) => ({
				...state,
				TPPrime: action.payload,
			}),
			GET_LINE_PRIME_FULFILLED: (state, action) => ({
				...state,
				TPPrime: action.payload,
			}),
			GET_JKO_PRIME_FULFILLED: (state, action) => ({
				...state,
				TPPrime: action.payload,
			}),
			CHECK_SUPPORT_APPLE_PAY_FULFILLED: (state, action) => ({
				...state,
				supportApplePay: action.payload,
			}),
			GET_APPLE_PRIME_FULFILLED: (state, action) => ({
				...state,
				TPPrime: action.payload,
			}),
			SET_FETCHING_TAPPAY: (state, action) => ({
				...state,
				fetchingTappay: action.payload,
			}),
			FETCH_TAPPAY_FULFILLED: (state, action) => ({
				...state,
				donateResult: action.payload,
			}),
		},
		{
			showError: false,
			donateStep: 1,
			donateType: '',
			donateAmount: {
				valid: false,
				amount: '',
			},
			donateLastname: {
				valid: false,
				lastname: '',
			},
			donateFirstname: {
				valid: false,
				firstname: '',
			},
			donateGender: {
				valid: false,
				gender: '',
			},
			donateNumber: {
				valid: false,
				number: '',
			},
			donateEmail: {
				valid: false,
				email: '',
			},
			donateCode: {
				valid: false,
				code: '',
			},
			donateDist: {
				valid: false,
				dist: '',
			},
			donateCity: {
				valid: false,
				city: '',
			},
			donateAddress: {
				valid: false,
				address: '',
			},
			supportApplePay: false,
			donatePayment: {
				valid: false,
				payment: '',
			},
			donateCardValidity: false,
			donateCardType: '',
			donateExpiryValidity: false,
			donateCCVValidity: false,
			TPPrime: '',
			fetchingTappay: false,
			donateResult: {
				status: null,
			},
		},
	),
};

const selectDonateForm = state => ({
	...state.donateForm,
});

export const useDonateForm = () =>
	useRedux(selectDonateForm, {
		setShowError,
		setDonateStep,
		setDonateType,
		setDonateAmount,
		setDonateLastname,
		setDonateFirstname,
		setDonateGender,
		setDonateNumber,
		setDonateEmail,
		setDonateDist,
		setDonateCity,
		setDonateAddress,
		checkSupportApplePay,
		setDonatePayment,
		setDonateCardValidity,
		setDonateCardType,
		setDonateExpiryValidity,
		setDonateCCVValidity,
		setFetchingTappay,
		fetchTappay,
	});

export default { reducer };
